/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.MuiBackdrop-root {
  @apply backdrop-blur-sm;
}

:root {
  --color-primary: 40 135 56;
}
